export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            students: [],
            attendances: [],
            dues: [],
            classTests: [],
            deleteStudents: [],
            promotions: [],
            tcs: [],
            menuItems: [
                {
                    title: 'Dashboard',
                    icon: 'mdi-apps',
                    to: '/student-panel',
                },
                {
                    title: 'Result',
                    icon: 'mdi-file-account',
                    to: '/student-result',
                },
                {
                    title: 'Attendance Report',
                    icon: 'mdi-fingerprint',
                    to: '/student-attendance',
                },
                {
                    title: 'Student Due',
                    icon: 'mdi-currency-bdt',
                    to: '/studentdue',
                },
                {
                    title: 'Due Payment',
                    icon: 'mdi-currency-bdt',
                    to: '/duepayment',
                },
                {
                    title: 'Payment Report',
                    icon: 'mdi-currency-bdt',
                    to: '/payment-report',
                },
                {
                    title: 'Class Test',
                    icon: 'mdi-file',
                    to: '/student-classtest',
                },
            ],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        menuItems(state) {
            return state.menuItems;
        },

        students(state) {
            return state.students.map((item, sl) => {
                item.sl = sl + 1;
                item.display_name = `${item.code} - ${item.name}`;
                item.approve_status = item.status == 'p' ? 'Pending': item.status == 'a' ? 'Approved' : ''
                return item;
            }).filter(s => s.status != 'd' && s.status != 't');
        },

        deleteStudents(state) {
            return state.deleteStudents.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },

        attendances(state) {
            return state.attendances.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        studentDues(state) {
            return state.dues
        },

        promotions(state) {
            return state.promotions.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        tcs(state) {
            return state.tcs.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        classTests(state) {
            return state.classTests.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setStudent(state, students) {
            state.students = students
        },

        setDeleteStudent(state, students) {
            state.deleteStudents = students
        },

        setAttendance(state, attendances) {
            state.attendances = attendances
        },

        setDues(state, dues) {
            state.dues = dues
        },

        setPromotion(state, promotion) {
            state.promotions = promotion
        },

        setTc(state, tc) {
            state.tcs = tc
        },

        setUser(state, student) {
            this.commit('authorized/setUser', student);
        },

        setClassTest(state, classtest) {
            state.classTests = classtest
        }
    },

    actions: {
        async getStudents(context, payload) {
            context.commit('setLoading', true);
        
            let students = await axios.post(`${this.state.host}/get-students`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.students
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            context.commit('setStudent', students);
            context.commit('setLoading', false);
        },

        async getStudentGroups(context) {
            let students = await axios.get(`${this.state.host}/get-student-groups`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.students
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return students;
        },

        async getDeleteStudent(context, payload) {
            context.commit('setLoading', true);

            await axios.post(`${this.state.host}/get-delete-student`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setDeleteStudent', res.data.students);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveStudent(context, {student, educations, image, fatherImage, motherImage, gurdianImage, signature, fatherSignature, motherSignature, feesCart}) {
            let isSuccess = false;
            let url = '';
            if(student.id != null) {
                url = 'update-student';
            } else {
                url = 'add-student';
                delete student.id
            }

            let fd = new FormData();
            fd.append('student', JSON.stringify(student));
            fd.append('educations', JSON.stringify(educations));
            fd.append('feesCart', JSON.stringify(feesCart));

            if(image != null && image != undefined) {
                fd.append('image', JSON.stringify(image));
            }

            if(fatherImage != null && fatherImage != undefined) {
                fd.append('fatherImage', JSON.stringify(fatherImage));
            }

            if(motherImage != null && motherImage != undefined) {
                fd.append('motherImage', JSON.stringify(motherImage));
            }

            if(gurdianImage != null && gurdianImage != undefined) {
                fd.append('gurdianImage', JSON.stringify(gurdianImage));
            }

            if(signature != null && signature != undefined) {
                fd.append('signature', JSON.stringify(signature));
            }

            if(fatherSignature != null && fatherSignature != undefined) {
                fd.append('fatherSignature', JSON.stringify(fatherSignature));
            }

            if(motherSignature != null && motherSignature != undefined) {
                fd.append('motherSignature', JSON.stringify(motherSignature));
            }

            await axios.post(`${this.state.host}/${url}`, fd, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getStudents');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteStudent(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-student/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getStudents');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async manuallyAttendance(context, payload) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/manually-attendance`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async attendanceProcess(context) {
            let isSuccess = false;

            await axios.get(`${this.state.host}/attendance-process`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async generateStudentCode(context, payload) {
            let newCode = await axios.post(`${this.state.host}/generate-student-code`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode;  
        },

        async generateBiomaticId(context) {
            let newCode = await axios.get(`${this.state.host}/get-student-biometric-id`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.biometric;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode;
        },

        async generateFormId(context) {
            let newCode = await axios.get(`${this.state.host}/get-form-id`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.formId;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode;
        },

        async generateIndexId(context) {
            let newCode = await axios.get(`${this.state.host}/get-index-id`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.indexId;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode;
        },

        async getStudentAttendances(context, payload) {
            context.commit('setLoading', true);
        
            let attendances = await axios.post(`${this.state.host}/get-student-attendances`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.attendances
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            context.commit('setAttendance', attendances);
            context.commit('setLoading', false);
        },

        async getStudentDues(context, payload) {
            context.commit('setLoading', true);
        
            let student = await axios.post(`${this.state.host}/get-student-dues`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.dues
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            context.commit('setDues', student);
            context.commit('setLoading', false);
        },

        async studentPromotion(context, payload) {
            let isSuccess = false;
            let url = 'add-student-promotion';
            
            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getPromotions(context, payload) {
            context.commit('setLoading', true);
        
            let promotion = await axios.post(`${this.state.host}/get-promotions`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.promotions
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            context.commit('setPromotion', promotion);
            context.commit('setLoading', false);
        },

        async restoreStudent(context, payload) {
            let isSuccess = false;
        
            await axios.post(`${this.state.host}/restore-student`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async generateTcCode(context) {
            let newCode = await axios.get(`${this.state.host}/generate-tc-code`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode; 
        },

        async getTc(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-tcs`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setTc', res.data.tcs);
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            
            context.commit('setLoading', false);
        },

        async saveTc(context, payload) {
            let isSuccess = false;
            let url = '';
            if(payload.id != null) {
                url = 'update-tc';
            } else {
                url = 'add-tc';
                delete payload.id 
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getTc')
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteTc(context, id) {
            let isSuccess = false;
            await axios.delete(`${this.state.host}/delete-tc/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message)
                context.dispatch('getTc');
            })
            .catch(error => {
                isSuccess = false;
                this.dispatch('snackbar/error', error)
            })

            return isSuccess;
        },

        async getStudentFees(context, payload) {
            let fees = await axios.post(`${this.state.host}/get-student-fees`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.fees;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return fees; 
        },

        async getMonthlyDue(context, payload) {
            let student = await axios.post(`${this.state.host}/get-monthly-dues`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.dues
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return student;
        },

        async getBranchwiseAttendance(context, payload) {
            context.commit('setLoading', true);
        
            let attendances = await axios.post(`${this.state.host}/get-branchwise-attendances`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.attendances
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            context.commit('setAttendance', attendances);
            context.commit('setLoading', false);
        },

        async getStudentAttendanceGraph(context) {
            let graph = await axios.get(`${this.state.host}/get-student-attendance-graph`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.attendances;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return graph;
        },

        async login(context, student) {
            let isSuccess = false;

            let auth = await axios.post(`${this.state.host}/student-login`, student)
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.commit('setUser', { user: res.data.user, jwt: res.data.jwt });

                localStorage.setItem('jwtToken', res.data.jwt)
                localStorage.setItem('userData', JSON.stringify(res.data.user))
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getStudentPortalDue(context) {
            let due = await axios.get(`${this.state.host}/get-student-portal-due`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.due;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return due;
        },

        async getStudentDuePayment(context) {
            let dues = await axios.get(`${this.state.host}/get-student-payment-due`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.dues;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return dues;
        },

        async getClassTests(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-student-classtests`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setClassTest', res.data.results);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async duePayment(context, payload) {
            let response = {success: false};

            await axios.post(`${this.state.host}/student-payment`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                response.success = true;
                response.url = res.data.url;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                response.success = false;
                this.dispatch('snackbar/error', error);
            })

            return response;
        },

        async getCollectionDue(context, payload) {
            let dues = await axios.post(`${this.state.host}/get-student-colleciton-due`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.dues;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return dues;
        }
    }
}